html, body {
  height:100%;
} 

.App {
  text-align: center;
}

.BackgroundGlobal {
  
}

.BackgroundGlobalNoStyle {
  
}

.groupButton {
  border-radius: 30px;
}

.marginTop {
  margin-top: 10%;
}

.marginLogo {
}

@media (max-width: 768px) {
  .marginTop {
    margin-top: 35%;
  }

  .marginLogo {
  }
}


.buttonMic {
  border-radius: 30px;
  color: #002657;
  background-color: white;
}

.buttonArrowDownMic {
  border-radius: 30px;
  background-color: #ffffff60;
  color: #002657;
}

.middleContainer {
  margin-top: 15%;
  display: flex; 
  flex-direction: column;
  align-items: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
