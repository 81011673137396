body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --rsbs-backdrop-bg: rgba(255, 0, 0, 0.6);
  --rsbs-bg: #FFFFFF75;
  --rsbs-handle-bg: hsla(0, 0%, 100%, 0.847);
  --rsbs-max-w: auto;
  --rsbs-ml: env(safe-area-inset-left);
  --rsbs-mr: env(safe-area-inset-right);
  --rsbs-overlay-rounded: 16px;
}

.MuiTabs-scrollButtons.Mui-disabled {
  opacity: 1;
  color:aliceblue
}

[data-rsbs-header] {
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  box-shadow: none !important;
}

[data-rsbs-is-blocking='false'] [data-rsbs-overlay]  {
  box-shadow: none !important;
  background-color: #86868620 !important;
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px);
}
[data-rsbs-is-blocking='false'] [data-rsbs-overlay] > div > h6 {
  color: white !important;
}

.MuiTabScrollButton-root {
  color: white !important;
}

fieldset {
  border: transparent !important;
}